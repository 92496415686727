import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import ContactUsForm from 'src/components/ContactUsForm'

import * as S from './styled'
import * as SB from 'src/styles/base'
import * as HS from 'src/components/Home/styled'

const ContactUs = () => {
  const { 
    strapiSecao7FacaUmOrcamento: { descricao, background } 
  } = useStaticQuery(
      graphql`
        query {
          strapiSecao7FacaUmOrcamento {
            descricao
            background {
              childImageSharp {
                fluid(maxWidth: 1900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
    `
  )

  return (
    <S.Background
      fluid={background.childImageSharp.fluid}
    >
      <S.SectionWrapper
        id="section-7-contact-us"
      >
        <HS.LargeScreenWrapper>
          <S.ContentWrapper>
            <SB.MarkdownContent
              styles={S.MarkdownStyles}
            >
                {descricao}
            </SB.MarkdownContent>

            <ContactUsForm />
          </S.ContentWrapper>
        </HS.LargeScreenWrapper>
      </S.SectionWrapper>
    </S.Background>
  )
}

export default ContactUs