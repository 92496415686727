import styled from 'styled-components'

import { globalMaxWidth } from 'src/commons'

export const LargeScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${globalMaxWidth};
  position: relative;
  z-index: 10;
`