import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Button from 'src/components/Button'
import { colors } from 'src/commons'

import * as S from './styled'
import * as HS from 'src/components/Home/styled'

const Apresentation = () => {
  const { strapiSecao1Apresentacao: {
    logo, background, titulo, subtitulo, texto_do_botao
  } } = useStaticQuery(
      graphql`
      query {
        strapiSecao1Apresentacao {
        logo {
          childImageSharp {
            fluid(maxWidth: 290){
              ...GatsbyImageSharpFluid
            }
          }
        }
        background {
          childImageSharp {
            fluid(maxWidth: 1900){
              ...GatsbyImageSharpFluid
            }
          }
        }
        texto_do_botao
        titulo
        subtitulo
        }
      }
    `
  )

  return (
    <S.Background
      Tag="section"
      fluid={background.childImageSharp.fluid}
      backgroundColor={colors.orange}
    >
      <S.SectionWrapper>
        <HS.LargeScreenWrapper>
          <S.ContentWrapper>
            <S.Title>
              {titulo}
            </S.Title>
            <S.Logo 
              fluid={logo.childImageSharp.fluid}
            />
            <S.Subtitle>
              {subtitulo}
            </S.Subtitle>
            <S.ButtonWrapper>
              <Button 
                text={texto_do_botao}
                type="white"
                href="#section-7-contact-us"
                clickGtag={{
                  tag:'scroll_to_contact_us', 
                  params: {button: 'section-1-presentation'}
                }}
              />
            </S.ButtonWrapper>
          </S.ContentWrapper>
          <S.IconVAPurpleOrange />
          <S.IconVWhite />
        </HS.LargeScreenWrapper>
      </S.SectionWrapper>
    </S.Background>
  )
}

export default Apresentation