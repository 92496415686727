import styled from 'styled-components'
import media from 'styled-media-query'

import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'
import Markdown from 'react-markdown'
import { colors } from 'src/commons'

import Svg_IconVAPurpleOrange from "../../../../assets/secao-1-va-colorido.svg";
import Svg_IconVWhite from "../../../../assets/secao-1-v-branco.svg";


export const Background = styled(BackgroundImage)`
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  background-position: 80% top;
`

export const SectionWrapper = styled.div`
  width: 100vw;
  height: 600px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow: hidden;

  ${media.lessThan("medium")`
    height: calc(100vh - 64px);
  `}
`

export const ContentWrapper = styled.div`
  width: 350px;
  margin: 0 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.lessThan("large")`
    margin: 0 50px;
    width: 270px;
  `}

  ${media.lessThan("medium")`
    width: 300px;
  `}

  ${media.lessThan("small")`
    width: 100%;
    margin: 0;
    padding: 0 20px;
  `}
`

export const Title = styled(Markdown)`
  color: ${colors.white};
  font-size: 55px;
  font-weight: bold;
  line-height: 73px;

  ${media.lessThan("large")`
    font-size: 40px;
    line-height: 62px;
  `}
`

export const Logo = styled(Image)`
  width: 290px;
  height: 40px;
  margin-top: 18px;
  margin-bottom: 78px;

  ${media.lessThan("large")`
    width: 261px;
    height: 35px;
    margin-bottom: 60px;
  `}
`

export const Subtitle = styled.p`
  font-size: 20px;
  font-weight: medium;
  color: ${colors.white};
  
  ${media.lessThan("large")`
    font-size: 18px;
  `}
`

export const ButtonWrapper = styled.div`
  margin-top: 30px;
`

export const IconVAPurpleOrange = styled(Svg_IconVAPurpleOrange)`
  top: -117px;
  left: 326px;
  position: absolute;
  height: 717px;

  ${media.lessThan("1315px")`
    left: 230px;
  `}

  ${media.lessThan("large")`
    left: 120px;
  `}

  ${media.lessThan("950px")`
    display: none;
  `}
`

export const IconVWhite = styled(Svg_IconVWhite)`
  right: -407px;
  top: -2px;
  position: absolute;
  height: 678px;
  z-index: -1;
  overflow: hidden;

  ${media.lessThan("large")`
    right: -407px;
  `}

  ${media.lessThan("medium")`
    right: -540px;
  `}
`