import React, {useEffect, useState, useCallback} from "react"

import Layout from "src/components/Layout"
import SEO from "src/components/seo"

import Presentation from 'src/components/Home/Presentation'
import ContactUs from 'src/components/Home/ContactUs'

const importDelay = (name) => import(`src/components/Home/${name}`).then(
  component => component.default
)

const componentsName = [
  'OurWork',
  'AboutUs',
  'OurProjects',
  'OurClients',
  'OurServices',
]

const IndexPage = () => {
  const [components, setComponents] = useState([])

  const loadComponents = useCallback(async (i) => {
    if (!(componentsName[i])) return

    const componentName = componentsName[i]
    const component = await importDelay(componentName)

    setComponents(prev => prev.concat({
      name: componentName,
      Component: component
    }))
  }, [])

  useEffect(() => {
    loadComponents(components.length)
  }, [components, loadComponents])

  return (
  <Layout>
    <SEO title="Transformamos a sua ideia em um negócio lucrativo" />
    <Presentation />
    {components.map(({name, Component}) => (
      <Component key={name} />
    ))}
    <ContactUs />
  </Layout>
)}

export default IndexPage
