import styled from 'styled-components'
import media from 'styled-media-query'

import BackgroundImage from 'gatsby-background-image'
import { colors } from 'src/commons'

export const Background = styled(BackgroundImage)`
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  background-position: 40% top;
`

export const SectionWrapper = styled.section`
  width: 100vw;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  scroll-margin-top: 54px;
`
  
export const ContentWrapper = styled.div`
  width: 400px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${media.lessThan("470px")`
    width: 100%;
    padding: 0 20px;
  `}
`

export const MarkdownStyles = `
  h2, p {
    color: ${colors.white};
  }
`